import React, { useState } from "react";
import { Link } from "react-scroll";
import "./App.css";
import finalLogo from './SyncPath_Consulting.png'; // Assuming the logo file is in the same directory

function App() {
  const [activeNav, setActiveNav] = useState("hero");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavClick = (section) => {
    setActiveNav(section);
    setIsMenuOpen(false); // Close menu on mobile after clicking a link
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  return (
    <div className="App" >
      {/* Navigation Bar */}
      <nav className="navbar">
        <div className="navbar-content">
          <div className="navbar-logo">
            <img src={finalLogo} alt="Logo" style={{ height: "40px" }} />
            <span className="company-name">SyncPath Consulting</span>
          </div>

          <span className="hamburger" onClick={toggleMenu}>☰</span>

          <ul className={`navbar-links ${isMenuOpen ? "show" : ""}`}>
            <li className="navTrue">
              <Link
                to="hero"
                smooth={true}
                duration={500}
                className={activeNav === "hero" ? "active" : ""}
                onClick={() => handleNavClick("hero")}
              >
                Home
              </Link>
            </li>
            <li className="navTrue">
              <Link
                to="services"
                smooth={true}
                duration={500}
                className={activeNav === "services" ? "active" : ""}
                onClick={() => handleNavClick("services")}
              >
                Services
              </Link>
            </li>
            <li className="navTrue">
              <Link
                to="upgrade"
                smooth={true}
                duration={500}
                className={activeNav === "upgrade" ? "active" : ""}
                onClick={() => handleNavClick("upgrade")}
              >
                App Upgrade
              </Link>
            </li>
            <li className="navTrue">
              <Link
                to="testimonials"
                smooth={true}
                duration={500}
                className={activeNav === "testimonials" ? "active" : ""}
                onClick={() => handleNavClick("testimonials")}
              >
                Success Stories
              </Link>
            </li>
            <li className="navTrue">
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className={activeNav === "contact" ? "active" : ""}
                onClick={() => handleNavClick("contact")}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Hero Section */}
      <div id="hero" className="hero-section" style={{ paddingTop: "50px" }}>
        <h1 style={{ color: "#003366", fontSize: "3rem", fontWeight: "bold" }}>
          Empower Your Business with Salesforce
        </h1>
        <p style={{ color: "#555", fontSize: "1.5rem", marginBottom: "20px" }}>
          Streamline your operations and accelerate growth with expert Salesforce consulting.
        </p>
        <Link to="services" smooth={true} duration={500}>
          <button
            style={{
              backgroundColor: "#003366", // Dark blue color for the button
              color: "#fff",
              padding: "15px 30px",
              fontSize: "1rem",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              marginTop: "10px", // Adjust button margin to position it better
            }}
          >
            Learn More
          </button>
        </Link>
      </div>

      {/* Services Section */}
      <div id="services" className="section services-section">
        <h2>Our Services</h2>
        <div className="services-cards">
          <div className="card">
            <h3>Salesforce Implementation</h3>
            <p>We provide seamless Salesforce setup and integration to meet your business needs.</p>
          </div>
          <div className="card">
            <h3>Salesforce Customization</h3>
            <p>Tailor your Salesforce platform with custom objects, fields, and workflows.</p>
          </div>
          <div className="card">
            <h3>Data Migration</h3>
            <p>Migrate your data securely to Salesforce without any loss of information.</p>
          </div>
          <div className="card">
            <h3>Consultation</h3>
            <p>Expert guidance to optimize your Salesforce use.</p>
          </div>
          <div className="card">
            <h3>Integration</h3>
            <p>Seamless integration with your existing tools and systems.</p>
          </div>
          <div className="card">
            <h3>Support</h3>
            <p>Ongoing support to ensure smooth operation.</p>
          </div>
          <div className="card">
            <h3>Process Automation</h3>
            <p>Automate complex workflows within Salesforce to boost efficiency and reduce manual tasks.</p>
          </div>
          <div className="card">
            <h3>Analytics & Reporting</h3>
            <p>Gain actionable insights with Salesforce’s analytics and reporting tools, tailored to your business goals.</p>
          </div>
          <div className="card">
            <h3>Salesforce Health Check</h3>
            <p>Conduct a comprehensive assessment of your Salesforce setup to identify and resolve performance issues.</p>
          </div>
          <div className="card">
            <h3>Mobile Optimization</h3>
            <p>Enable seamless access to Salesforce on mobile devices with custom mobile solutions.</p>
          </div>
          <div className="card">
            <h3>Salesforce App Development</h3>
            <p>Develop custom applications within Salesforce to meet your unique business requirements.</p>
          </div>
          <div className="card">
            <h3>Data Cleansing & Enrichment</h3>
            <p>Enhance your Salesforce data quality with data cleansing, standardization, and enrichment services.</p>
          </div>
        </div>
      </div>
    
      {/* App Upgrade Section */}
      <div id="upgrade" className="section upgrade-section">
          <h2>Optimize and Upgrade Your Salesforce Application</h2>
          <p className="upgrade-description">
              Maximize productivity and drive growth by consistently updating your Salesforce environment with the latest features, advanced security protocols, and optimized workflows.
          </p>
          <div className="upgrade-cards">
              <div className="upgrade-card"><strong>Boost Efficiency:</strong> Implement the newest automation tools to streamline business processes, reducing manual effort and increasing operational speed.</div>
              <div className="upgrade-card"><strong>Enhanced Functionality:</strong> Integrate the latest Salesforce features like AI-driven insights, machine learning, and predictive analytics to make smarter, data-driven decisions.</div>
              <div className="upgrade-card"><strong>Industry-Specific Solutions:</strong> Tailor Salesforce to align with your industry needs, whether it’s financial services, healthcare, or retail, for a competitive advantage.</div>
              <div className="upgrade-card"><strong>Stay Secure:</strong> Safeguard sensitive data with the latest security updates, compliance measures, and multi-layered authentication options.</div>
              <div className="upgrade-card"><strong>Seamless Integration:</strong> Ensure compatibility with third-party apps and existing systems by staying current with Salesforce’s ecosystem updates.</div>
              <div className="upgrade-card"><strong>Optimized Performance:</strong> Keep your Salesforce instance running smoothly by addressing performance bottlenecks and applying best practices for scalability.</div>
              <div className="upgrade-card"><strong>User Training and Adoption:</strong> Educate and empower your team to leverage new features fully, ensuring optimal usage and user satisfaction.</div>
              <div className="upgrade-card"><strong>Custom App Development:</strong> Develop customized applications and enhancements within Salesforce to meet specific business objectives and streamline tasks.</div>
          </div>
          <p className="upgrade-footer">
              Regular updates and enhancements are crucial to maintaining a high-performance Salesforce environment that meets your evolving business needs. Leverage the latest innovations to stay ahead in the market.
          </p>
          <p className="upgrade-contact">
              Contact us to discuss how a comprehensive upgrade strategy can transform your Salesforce experience and bring your business goals within reach.
          </p>
      </div>

     {/* Testimonials Section */}
      <div id="testimonials" class="testimonials-section">
    <h2 class="section-heading">Success Stories</h2>
    <p class="section-subtitle">Discover how our Salesforce consulting services have empowered businesses to achieve seamless growth and productivity.</p>
    <div class="testimonials-container">
      <div class="testimonial">
        <p class="testimonial-quote">"The team helped us optimize our Salesforce setup, which led to significant productivity gains."</p>
        <p class="testimonial-cite">- John Doe, Tech Solutions Inc.</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"Their expert consultation made our transition to Salesforce smooth and efficient."</p>
        <p class="testimonial-cite">- Sarah Williams, Innovate Systems</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"Thanks to their seamless integration services, our business processes are more efficient than ever."</p>
        <p class="testimonial-cite">- Mark Taylor, FutureTech Labs</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"We saw a 30% increase in sales efficiency after their customized Salesforce solutions."</p>
        <p class="testimonial-cite">- Emily Johnson, BrightPath Enterprises</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"Their support team is incredibly responsive and always ready to solve any issue that comes up."</p>
        <p class="testimonial-cite">- David Miller, Global Networks Co.</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"Our business operations became smoother and more productive after implementing their solutions."</p>
        <p class="testimonial-cite">- Lisa Brown, Skywave Technologies</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"Their expertise in Salesforce allowed us to streamline processes and significantly improve customer satisfaction."</p>
        <p class="testimonial-cite">- Michael Green, Apex Innovations</p>
      </div>
      <div class="testimonial">
        <p class="testimonial-quote">"Their insights into Salesforce customization have greatly enhanced our workflow efficiency."</p>
        <p class="testimonial-cite">- Rachel Adams, Quantum Corp.</p>
      </div>
    </div>
      </div>

      {/* Contact Section */}
      <div id="contact" class="contact-section">
        <h2>Contact Us</h2>
        <div class="contact-details">
          <p><strong>Address:</strong> Uday Raj, Patrakar Colony Extension, Mansarovar, Jaipur, 302029</p>
          <p><strong>Contact No.:</strong> 9057505529</p>
          <p><strong>Email:</strong> Vaibhav77324@gmail.com</p>
        </div>
        <div class="contact-form-tile">
          <form>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email" />
            <textarea placeholder="Your Message"></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

// Hand gesture on click
document.body.addEventListener('click', function(e) {
  const x = e.clientX;
  const y = e.clientY;
  
  // Only show gesture when clicking outside the navbar
  if (!e.target.closest('.navbar')) {
    const gesture = document.createElement('div');
    gesture.classList.add('gesture');
    gesture.style.position = 'absolute';
    gesture.style.left = `${x}px`;
    gesture.style.top = `${y}px`;

    document.body.appendChild(gesture);

    setTimeout(() => {
      document.body.removeChild(gesture);
    }, 1000); // Remove the gesture after 1 second
  }
});


export default App;
